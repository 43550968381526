<template>
  <div class="location">
    <Loader v-if="isLoading" />
    <div
      :class="containerClass"
      v-else
    >
      <div class="flex flex-col" v-show="showBuildingSelector">
        <Select
          v-if="!props.areaOnly"
          name="buildings"
          :options="buildingsOptions"
          :label="props.noLabel ? undefined : t('resources.buildings.name', 1)"
          :model-value="location.building_id"
          @update:model-value="(value: number) => selectBuilding(value)"
          :disabled="disableBuilding"
          :isGreyBg="props.isGreyBg"
        />
      </div>

      <div
        v-if="selectedBuilding && floors && floors.length > 0"
        class="flex flex-col"
      >
        <Select
          v-if="!props.areaOnly"
          name="floors"
          :options="floorsOptions"
          :label="props.noLabel ? undefined : t('resources.floors.name', 1)"
          :model-value="location.floor_id"
          @update:model-value="(value: number) => selectFloor(value)"
          :isGreyBg="props.isGreyBg"
        />
      </div>

      <div
        v-if="selectedFloor && areas && areas?.length > 0"
        class="flex flex-col"
      >
        <Select
          v-if="!props.areaOnly"
          name="floors"
          :options="areasOptions"
          :label="props.noLabel ? undefined : t('resources.areas.name', 1)"
          :model-value="location.area_id"
          @update:model-value="(value: number) => selectArea(value)"
          :isGreyBg="props.isGreyBg"
        />
      </div>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "select": "Select",
    "whole_location": "Any",
    "zoom_area": "Zoom Area"
  },
  "fr": {
    "select": "Sélectionner",
    "whole_location": "N'importe",
    "zoom_area": "Zone zoom"
  }
}
</i18n>

<script setup lang="ts">
import Loader from '@/components/comeen/utils/loading/Loader.vue'
import { listBuildings } from '@/utils/api/buildingActions'
import Select from '@/components/comeen/utils/forms/Select.vue'
import type { Building } from '@/utils/types/buildings'

const { t } = useI18n({ useScope: 'local' })
const auth = useAuth()

const emit = defineEmits(['update:modelValue'])

export type Location = {
  building_id: number | null
  floor_id: number | null
  area_id: number | null
}

type Props = {
  areaOnly?: boolean
  noLabel?: boolean
  oneRow?: boolean
  preloadBuildings?: Array<Building>
  disableBuilding?: boolean
  isGreyBg?: boolean
  inline?: boolean
  showBuildingSelector?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  areaOnly: false,
  noLabel: false,
  oneRow: false,
  showBuildingSelector: true,
})

const location = defineModel<Location>({
  required: true,
  default: {
    building_id: null,
    floor_id: null,
    area_id: null,
  },
})

const { data: allBuildings, refresh: fetchBuildings } = await useAsyncData(
  'buildings',
  () => listBuildings(auth.space.uuid),
  {
    immediate: false,
  },
)
const isLoading = computed(() => !props.preloadBuildings && !allBuildings)
const buildings = computed(
  () =>
    (props.preloadBuildings ?? toValue(allBuildings))?.toSorted?.((a, b) =>
      a.name.localeCompare(b.name),
    ) ?? [],
)
onMounted(async () => {
  if (props.preloadBuildings === undefined) {
    await fetchBuildings()
  }
})

const buildingsOptions = computed(
  () =>
    toValue(buildings)?.map((building) => ({
      label: building.name,
      value: building.id,
    })) ?? [],
)
const selectedBuilding = computed(() =>
  toValue(buildings)?.find(
    (building) => building.id === toValue(location).building_id,
  ),
)

const floors = computed(() => toValue(selectedBuilding)?.floors ?? [])
const floorsOptions = computed(() => [
  {
    label: t('whole_location'),
    value: null,
  },
  ...(toValue(floors)?.map((floor) => ({
    label: floor.name,
    value: floor.id,
  })) ?? []),
])
const selectedFloor = computed(() =>
  toValue(floors)?.find((floor) => floor.id === toValue(location).floor_id),
)

const areas = computed(() => toValue(selectedFloor)?.areas ?? [])
const areasOptions = computed(() => [
  {
    label: t('whole_location'),
    value: null,
  },
  ...(toValue(areas)?.map((area) => ({
    label: area.name,
    value: area.id,
  })) ?? []),
])

const selectBuilding = (buildingId: Location['building_id']) => {
  location.value = {
    building_id: buildingId,
    floor_id: null,
    area_id: null,
  }
}

const selectFloor = (floorId: Location['floor_id']) => {
  if (!toValue(location).building_id) {
    return
  }

  location.value = {
    ...toValue(location),
    floor_id: floorId,
    area_id: null,
  }
}

const selectArea = (areaId: Location['area_id']) => {
  if (!toValue(location).building_id || !toValue(location).floor_id) {
    return
  }

  location.value = {
    ...toValue(location),
    area_id: areaId,
  }
}

const containerClass = computed(() => {
  if (props.inline) {
    return 'flex items-center gap-2 *:flex-0 *:min-w-52'
  }

  return ['grid gap-2', props.oneRow ? 'grid-cols-1' : 'grid-cols-1 xl:grid-cols-2']
})

defineOptions({
  name: 'SingleLocationSelect',
})
</script>

<style lang="scss">
.location {
  .vs-select--input {
    background: white;
  }
}
</style>
